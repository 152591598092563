// Setup Honeybadger
import Honeybadger from '@honeybadger-io/js';
import { Notyf } from 'notyf';
import { datadogRum } from '@datadog/browser-rum';

if (process.env.HONEYBADGER_API_KEY) {
  console.log(`Launching ${process.env.RAILS_ENV} Honeybadger Vue`);
  Honeybadger.configure({
    apiKey: process.env.HONEYBADGER_API_KEY,
    environment: process.env.RAILS_ENV,
  });
}

if (process.env.RAILS_ENV !== 'development') {
  console.info('datadog launched');
  datadogRum.init({
    applicationId: 'e9ae22a2-c2ca-4fdc-999f-19220f79a6ac',
    clientToken: 'pub06fff730e12e01ac989ffe06887946ba',
    site: 'datadoghq.com',
    service: 'sponsor-our-students',
    env: process.env.RAILS_ENV,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 10,
    trackUserInteractions: process.env.RAILS_ENV === 'production',
    trackResources: process.env.RAILS_ENV === 'production',
    trackLongTasks: process.env.RAILS_ENV === 'production',
    defaultPrivacyLevel: 'mask-user-input',
  });
}

const { id, dataset } = document.body;
const { flashNotice, flashAlert } = dataset;

// check for flash
const notyf = new Notyf({
  duration: 10000,
  position: {
    x: 'right',
    y: 'top',
  },
  dismissible: true,
});
window.notyf = notyf;

if (flashAlert) {
  notyf.error(flashAlert);
} else if (flashNotice) {
  notyf.success(flashNotice);
}

if (id === 'home-index') {
  import('../js/views/home/index');
} else if (id === 'dpez_dealers-index') {
  import('../js/views/dpez_dealers/index');
} else if (id === 'dpez_dealers-kpi') {
  import('../js/views/dpez_dealers/kpi');
} else if (id === 'students-verify') {
  import('../js/views/students/verify');
}


// Profile Dropdown
const profileMenuLink = document.getElementById('profile-menu-link');
const profileDropdown = document.getElementById('profile-menu-dropdown');

if (profileMenuLink && profileDropdown) {
  profileMenuLink.addEventListener('click', (e) => {
    // e.preventDefault();
    profileDropdown.classList.toggle('show');
  });
}
